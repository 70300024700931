<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Assinatura</h1>
            </div>
            <div class="row">
              <div class="ui active inverted dimmer" v-if="loading">
                <div class="ui loader"></div>
              </div>
              <div
                class="subscription stackable ui grid sixteen column wide"
                v-if="Object.keys(subscription).length"
              >
                <div class="sixteen wide column">
                  <div class="subscription__user">
                    <div class="subscription__profile">
                      <div class="subscription__profile-photo">
                        <img :src="gravatar" />
                      </div>
                      <!-- <h2>{{ subscription.customerProfile.name }}</h2> -->
                      <h2>
                        {{
                          subscription.details.client.firstName +
                            " " +
                            subscription.details.client.lastName
                        }}
                      </h2>
                      <!-- <p>{{ subscription.customerProfile.email }}</p> -->
                      <p>{{ subscription.details.client.email }}</p>
                      <p>
                        <strong>Tel:</strong>
                        <!-- {{ subscription.customerProfile.phone }} -->
                        {{ subscription.details.client.phone }}
                      </p>
                      <p>
                        <strong>Pedido Original:</strong>
                        <!-- {{ subscription.originalOrderId }} -->
                        {{ subscription.originalOrderId }}
                      </p>
                      <p>
                        <strong>Criado em:</strong>
                        {{
                          $moment(subscription.createdAt)
                            .tz($moment.tz.guess())
                            .format("DD MMMM YYYY")
                        }}
                      </p>
                    </div>
                    <div class="subscription__info">
                      <div class="subscription__shipping">
                        <h3>Dados de entrega</h3>
                        <p>
                          <strong>Rua:</strong>
                          <!-- {{ subscription.shippingAddress.street }},
                          {{ subscription.shippingAddress.number }} -->
                          {{ subscription.details.shipping_address.street }},
                          {{ subscription.details.shipping_address.number }}
                        </p>
                        <p>
                          <strong>Bairro:</strong>
                          <!-- {{ subscription.shippingAddress.neighborhood }} -->
                          {{
                            subscription.details.shipping_address.neighborhood
                          }}
                        </p>
                        <!-- <p>
                          <strong>CEP:</strong>
                          {{ subscription.shippingAddress.postalCode }}
                        </p> -->
                        <p>
                          <strong>Cidade:</strong>
                          <!-- {{ subscription.shippingAddress.city }} -->
                          {{ subscription.details.shipping_address.city }}
                        </p>
                      </div>
                      <div class="subscription__plan">
                        <h3>Dados do plano</h3>
                        <p>
                          <strong>Periodicidade:</strong>
                          {{ subscription.plan.frequency.periodicity }}
                        </p>
                        <p>
                          <strong>Validade:</strong>
                          {{
                            $moment(subscription.plan.validity.begin)
                              .tz($moment.tz.guess())
                              .format("DD MMM YYYY")
                          }}
                          até
                          <span v-if="subscription.plan.validity.end">
                            {{
                              $moment(subscription.plan.validity.end)
                                .tz($moment.tz.guess())
                                .format("DD MMM YYYY")
                            }}
                          </span>
                        </p>
                        <p>
                          <strong>Próxima cobrança:</strong>
                          {{
                            $moment(subscription.nextPurchaseDate)
                              .tz($moment.tz.guess())
                              .format("DD MMM YYYY | hh:mm:ss")
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="subscription__cancel">
                      <button
                        class="ui red button"
                        @click="() => openModal('modalCancellationReason')"
                        :disabled="disableBTNCancelSubscription"
                      >
                        Cancelar Assinatura
                      </button>
                      <!-- <button class="ui red button" @click="() => openModal('modalCancellationReason')">Cancelar Assinatura <span v-if="disableBTNCancelSubscription==true">(Já cancelada)</span></button> -->
                    </div>
                  </div>
                </div>
                <div class="six wide column">
                  <h2>Produto na assinatura</h2>
                  <div class="subscription__product">
                    <div class="subscription__product-item">
                      <div class="subscription__product-image">
                        <!-- <img
                          :src="subscription.item.sku.imageUrl"
                          :alt="subscription.item.sku.name"
                        /> -->
                        <img
                          width="150"
                          height="175"
                          :src="subscription.details.product.imageUrl"
                          :alt="subscription.details.product.name"
                        />
                      </div>
                      <div class="subscription__product-info">
                        <p class="subscription__product-info-title">
                          <!-- {{ subscription.item.sku.nameComplete }} -->
                          {{ subscription.details.product.productName }}
                        </p>
                        <p class="subscription__product-info-id">
                          <!-- ID: {{ subscription.item.sku.id }} -->
                          ID: {{ subscription.sku.id }}
                        </p>
                        <!-- <a
                          :href="'https://www.livrariart.com.br' + subscription.item.sku.detailUrl"
                          target="_blank"
                          class="ui button button__primary subscription__product-info-link"
                          >Ver para produto</a> -->
                      </div>
                    </div>
                  </div>
                  <div v-if="subscription.cancellationRecord">
                    <h2>Assinatura Cancelada</h2>
                    <div class="subscription__cancellation-record">
                      <div class="subscription__cancellation-record-content">
                        <div class="subscription__cancellation-record-text">
                          <p>
                            <strong>Responsável:</strong>
                            {{
                              subscription.cancellationRecord.userResponsible
                            }}
                          </p>
                          <p>
                            <strong>Motivo:</strong>
                            {{ subscription.cancellationRecord.reason }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ten wide column">
                  <h2>Ciclos</h2>
                  <div
                    class="ui styled fluid accordion subscription__instances"
                  >
                    <div
                      class="instances__item"
                      v-for="instance in subscription.cycles"
                      :key="instance.id"
                    >
                      <div class="title">
                        <i class="dropdown icon"></i>
                        Ciclo #{{ instance.cycleCount }}
                      </div>
                      <div class="content">
                        <p>
                          <strong>Status:</strong>
                          {{ instance.status }}
                        </p>
                        <p>
                          <strong>Criado:</strong>
                          {{
                            $moment(instance.date)
                              .tz($moment.tz.guess())
                              .format("DD MMM YYYY | hh:mm:ss")
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="ui mini modal"
          name="modalCancellationReason"
          :class="{ active: modals.modalCancellationReason }"
        >
          <div class="header">
            <span>Informe o motivo do cancelamento</span>
          </div>
          <div class="content">
            <div class="ui form input">
              <div class="required field">
                <label>Por favor informe o motivo do cancelamento</label>
                <textarea
                  rows="2"
                  id="motivo_cancelamento_assinatura"
                  v-model="cancellationReason"
                  placeholder="Motivo do cancelamento"
                ></textarea>
              </div>
            </div>
            <p>
              <small>
                <i
                  >*Esta informa&ccedil;&atilde;o &eacute; obrigat&oacute;ria</i
                >
              </small>
            </p>
          </div>
          <div class="actions">
            <button
              class="ui button positive"
              :disabled="!cancellationReason.length"
              @click="
                () =>
                  cancelSubscriptionById(subscription.id, cancellationReason)
              "
            >
              OK
            </button>
            <button
              class="ui button negative"
              @click="closeModal('modalCancellationReason')"
            >
              Fechar
            </button>
          </div>
        </div>
        <!-- -- -->
        <div
          class="ui mini modal"
          name="modalCancellationFeedback"
          :class="{ active: modals.modalCancellationFeedback }"
        >
          <div class="header">
            <span
              >Cancelamento {{ !canceled ? "N&atilde;o" : "" }} Efetuado</span
            >
          </div>
          <div class="content">
            <p>
              {{
                !canceled
                  ? "Não foi poss&iacute;vel cancelar."
                  : "A assinatura foi cancelada."
              }}
            </p>
            <p>
              <i>{{ message }}</i>
            </p>
          </div>
          <div class="actions">
            <button
              class="ui button"
              :class="{ positive: canceled, negative: !canceled }"
              @click="closeModal('modalCancellationFeedback')"
            >
              OK
            </button>
          </div>
        </div>
        <!-- -- -->
        <div
          class="ui mini modal"
          :class="{ active: modals.modalSubscriptionNotFound }"
        >
          <div class="header">
            <span>Dados N&atilde;o Encontrados</span>
          </div>
          <div class="content">
            <p>Não foi poss&iacute;vel encontrar os dados da assinatura</p>
            <p>
              <i>{{ message }}</i>
            </p>
          </div>
          <div class="actions">
            <button
              class="ui button negative"
              @click="closeModal('modalSubscriptionNotFound')"
            >
              OK
            </button>
          </div>
        </div>
      </main>
      <div
        class="ui dimmer modals page transition"
        :class="{ active: modalBackdrop }"
        v-on:click="closeModal"
      ></div>
    </div>
  </Backoffice>
</template>

<script>
import SystemLoading from "../../../../utils/SystemLoading";
import Backoffice from "../../../Template/Backoffice";
import md5 from "md5";

export default {
  name: "Subscription",
  components: {
    Backoffice
  },
  computed: {},
  data() {
    return {
      message: "",
      loading: false,
      canceling: false,
      canceled: false,
      subscription: {
        customerId: "",
        customerProfile: {},
        shippingAddress: {},
        plan: {
          frequency: {},
          validity: {}
        },
        item: {
          sku: ""
        },
        details: {
          client: {},
          shipping_address: {},
          product: {}
        }
      },
      modals: {
        modalCancellationFeedback: false,
        modalCancellationReason: false,
        modalSubscriptionNotFound: false
      },
      cancellationReason: ""
    };
  },
  computed: {
    gravatar() {
      let vm = this;

      const { customerEmail } = this.subscription;
      const emailHash = customerEmail
        ? md5(this.subscription.customerEmail)
        : "default";

      return `https://secure.gravatar.com/avatar/${emailHash}?size=100&d=mp`;
    },
    disableBTNCancelSubscription() {
      let status = this.subscription.status || "";
      let disabled = status.toUpperCase() === "CANCELED" || this.canceling;

      return disabled;
    },
    modalBackdrop() {
      let show = false;

      for (const modal in this.modals) {
        if (this.modals[modal] === true) {
          show = true;
          break;
        }
      }

      return show;
    }
  },
  methods: {
    openModal(modalName = "modalCancellationFeedback") {
      if (!modalName) return;

      if (!this.modals[modalName]) {
        this.modals[modalName] = true;
      }
    },
    closeModal(modalName = "") {
      if (!modalName) {
        for (const modal in this.modals) {
          this.modals[modal] = false;
        }

        return;
      }

      if (this.modals[modalName]) {
        this.modals[modalName] = false;
      }
    },
    getSubscriptionById(subscriptionId, subscriptionGroupId = null) {
      this.loading = true;

      window.vm = this;

      let action = "Subscriptions/GET_SUBSCRIPTION_BY_ID";

      if (subscriptionGroupId) {
        action = "Subscriptions/GET_SUBSCRIPTION_BY_ID_FROM_GROUP";
      }

      this.$store
        .dispatch(action, { subscriptionId, subscriptionGroupId })
        .then(_response => {
          this.loading = false;

          if (!_response) {
            this.message = `Assinatura ID ${subscriptionId}`;
            this.openModal("modalSubscriptionNotFound");
          } else {
            this.subscription = _response;

            this.$nextTick(function() {
              $(".subscription__instances").accordion();
            });
          }
        })
        .catch(({ response }) => {
          const { message } = response.data;
          this.message = message;
        });
    },
    cancelSubscriptionById(id = null, reason = "") {
      if (!id || !reason) return;
      console.log("Cancelamento iniciado ...");
      this.closeModal();

      this.canceling = true;

      SystemLoading.show("Processando");

      this.$store
        .dispatch("Subscriptions/CANCEL_SUBSCRIPTION_BY_ID", { id, reason })
        .then(({ canceled, message }) => {
          this.message = message;
          this.canceled = canceled;

          this.$nextTick(() => {
            window.setTimeout(() => {
              this.closeModal();
            }, 3800);
          });

          this.getSubscriptionById(id, this.$route.params.groupId);
        })
        .catch(({ response }) => {
          const { canceled, message } = response.data;

          this.message = message;
          this.canceled = canceled;
        })
        .then(() => {
          this.canceling = false;

          this.$nextTick(() => {
            SystemLoading.close();
            this.openModal("modalCancellationFeedback");
          });
        });
    }
  },
  beforeCreate() {},
  created() {
    const vm = this;
    vm.getSubscriptionById(vm.$route.params.id, vm.$route.params.groupId);
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/globals/colors";
@import "~@/assets/styles/globals/buttons";
.ui.mini.modal {
  top: 200px;
}
.subscription {
  width: 100%;
}
h2 {
  margin-top: 20px;
}
.subscription__cancel {
  display: flex;
  flex-direction: column-reverse;
  width: 180px;
}
.subscription__cancel button {
  font-size: 12px;
}
.subscription__user {
  display: flex;
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.subscription__profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 10px;
  &-photo {
    display: inline-block;
    border-radius: 50%;
    max-width: 100px;
    height: 100px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.subscription__info {
  width: 50%;
  padding: 10px;
  p {
    margin-bottom: 0;
  }
}
.subscription__shipping {
  margin-bottom: 20px;
}
.subscription__cancellation-record {
  &-body {
    display: flex;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
  }

  &-content {
    display: flex;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
  }

  &-text {
    padding: 10px;
    width: 100%;
  }
}
.subscription__product {
  &-item {
    display: flex;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  &-image {
    padding: 10px;
    img {
      border-radius: 50px;
      display: block;
      border: 1px solid $color-gray-light;
      overflow: hidden;
    }
  }
  &-info {
    padding: 10px;
    width: 100%;
  }
  &-info-title {
    font-weight: bold;
    margin: 0;
  }
  &-info-id {
    font-size: 12px;
  }
  &-info-link {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-top: 0px;
  }
  .ui.stackable.grid {
    width: 100%;
  }
  .subscription__user {
    flex-direction: column;
  }
  .subscription__profile,
  .subscription__info {
    width: 100%;
  }
  .subscription__profile {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  .subscription__info {
    justify-content: center;
  }
}
</style>
